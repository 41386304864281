import * as React from "react";
import * as Router from "react-router-dom";
import * as Query from "react-query";
import * as Config from "configuration";
import * as Format from "format";
import * as Auth from "oauth2";
import EcoleSelector from "./ecole-selector";
import Avatar from "@valraiso-esf/esf-ui/es/avatar";
import ManageAccountsIcon from "@valraiso-esf/esf-icons/es/manage-accounts";

import css from "./account.module.css";

type Ecole = { code: number; name: string; isESF: boolean };
type Props = {
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

const ACCOUNT_ROUTE = "/mon-compte";

export default function Account({ onClick }: Props) {
  const auth = Auth.useAuth();
  const ecoles = Query.useQuery("ecoleSelector", () =>
    fetchFilteredEcoles(auth.user)
  );

  if (!auth.user) return null;

  const prenom = Format.capitalize(auth.user.prenom ?? "");
  const nom = Format.capitalize(auth.user.nom);

  const fullName = (prenom + " " + nom).trim();
  return (
    <div className={css.outer}>
      <Router.Link
        to={ACCOUNT_ROUTE}
        onClick={onClick}
        className={css.avatarLink}
      >
        <Avatar
          initials={Format.initials(fullName)}
          imageUrl={avatar(auth.user)}
          size={48}
        />
      </Router.Link>
      <div className={css.who}>
        <Router.Link to={ACCOUNT_ROUTE} onClick={onClick} className={css.name}>
          {fullName}
        </Router.Link>

        {ecoles.data && <EcoleSelector ecoles={ecoles.data} />}
      </div>
      <Router.Link
        to={ACCOUNT_ROUTE}
        onClick={onClick}
        className={css.manageAccounts}
      >
        <ManageAccountsIcon />
      </Router.Link>
    </div>
  );
}

function avatar(user: Auth.User) {
  if (!user.photo || !user.noEcole) return undefined;
  const api = Config.get("apiAnnuaire");
  return `${api}/api/annuaire/public/moniteur/${user.noEcole}/${user.login}/photo`;
}

function filters(user: Auth.User) {
  if (!user.noEcole) return undefined;
  const filters = [user.noEcole];
  return user.noEcolesRenforts
    ? filters.concat(user.noEcolesRenforts)
    : filters;
}

const origin = Config.get("apiCarnetRouge");
async function fetchFilteredEcoles(user?: Auth.User) {
  if (!user) return undefined;
  const ecoles = filters(user) ?? [];
  const api = `${origin}/api/carnet-rouge/ecoles`;
  const qs = ecoles.map((ecole) => `filter=${ecole}`).join("&");
  const url = api + (qs.length > 0 ? "?" + qs : "");
  const response = await fetch(url);
  return response.json() as Promise<Ecole[]>;
}
