import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames_ from "classnames";
import * as React from "react";
import * as ReactDOM from "react-dom";
import LoaderCircle from "./loader-circle";
import CloseIcon from "@valraiso-esf/esf-icons/es/close";
import Button from "./button";
import css from "./dialog.module.css";
const zone = getZone();
export default function DialogBuilder(props) {
    const { children, ...rest } = props;
    const [opened, setOpened] = React.useState(false);
    React.useEffect(() => {
        if (props.open !== opened) {
            if (props.open) {
                setOpened(true);
            }
            else {
                setTimeout(() => {
                    setOpened(false);
                }, 240);
            }
        }
    }, [opened, props.open]);
    return opened ? _jsx(Dialog, { ...rest, children: props.children }) : null;
}
function Dialog({ children, title, noTitle, actions, loading = false, className, classNames, stickToBottom = false, onClose, open, }) {
    const refDialog = React.useRef(null);
    const [opened, setOpened] = React.useState(false);
    const [mouseDown, setMousedown] = React.useState();
    React.useEffect(() => {
        requestAnimationFrame(() => setOpened(open));
        preventScroll();
        preventFocus();
        return () => {
            restoreScroll();
            restoreFocus();
        };
    }, [open]);
    const overlayClasses = classnames_(classNames?.overlay, css.overlay, {
        [css.opened]: opened,
        [css.stickToBottom]: stickToBottom,
    });
    const dialogClasses = classnames_(classNames?.dialog, className, css.inner);
    const titleAndCloseClasses = classnames_(classNames?.title, css.titleAndClose, {
        [css.noBorder]: !title,
        [css.floatingTitle]: noTitle,
    });
    const contentClasses = classnames_(classNames?.content, css.content, {
        [css.loading]: loading,
    });
    const actionsClasses = classnames_(classNames?.actions, css.actions);
    return ReactDOM.createPortal(_jsx("div", { className: overlayClasses, onMouseDown: () => {
            setMousedown("overlay");
        }, onClick: () => {
            if (mouseDown === "overlay") {
                onClose();
            }
        }, children: _jsxs("div", { ref: refDialog, "aria-modal": true, role: "dialog", className: dialogClasses, onClick: (e) => e.stopPropagation(), onMouseDown: (e) => {
                e.stopPropagation();
                setMousedown("dialog");
            }, children: [_jsxs("div", { className: titleAndCloseClasses, children: [_jsx("div", { className: css.title, children: title }), _jsx(Button, { variant: "icon", color: "tertiary", size: "M", className: css.closeIcon, onClick: onClose, children: _jsx(CloseIcon, {}) })] }), children && _jsx("div", { className: contentClasses, children: children }), actions && (_jsxs("div", { className: actionsClasses, children: [!loading && actions, loading && _jsx(LoaderCircle, {})] }))] }) }), zone);
}
const bodyOverflow = document.body.style.overflow;
const setOverflow = (overflow) => (document.body.style.overflow = overflow);
const preventScroll = () => setOverflow("hidden");
const restoreScroll = () => setOverflow(bodyOverflow);
// TODO : mieux gérer les superposition/interactions (modal, popover, file-manager, etc.)
const inertExclusions = ["SCRIPT", "LINK", "NOSCRIPT"];
const inertExclusionsId = ["file-manager-modal"];
function trapFocus(trap) {
    for (const el of document.body.children) {
        if (el !== zone &&
            !inertExclusions.includes(el.nodeName) &&
            !inertExclusionsId.includes(el.id)) {
            el.inert = trap;
        }
    }
}
const preventFocus = () => trapFocus(true);
const restoreFocus = () => trapFocus(false);
function getZone() {
    const zoneId = "esf-dialog-zone";
    let zone = document.querySelector(`body > #${zoneId}`);
    if (!zone) {
        zone = document.createElement("div");
        zone.id = zoneId;
        document.body.append(zone);
    }
    return zone;
}
