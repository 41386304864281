import * as React from "react";
import * as App from "global-state";
import * as Notification from "notification-state";
import * as Auth from "oauth2";
import * as Router from "react-router-dom";
import classNames from "classnames";
import NavigationMenu from "./navigation-menu";
import Loader from "@valraiso-esf/esf-ui/es/loader-circle";
import MenuIcon from "@valraiso-esf/esf-icons/es/menu";
import CloseIcon from "@valraiso-esf/esf-icons/es/close";
import NotificationsIcon from "@valraiso-esf/esf-icons/es/notifications";
import Button from "@valraiso-esf/esf-ui/es/button";
import Toast from "@valraiso-esf/esf-ui/es/toast";
import ErrorIcon from "@valraiso-esf/esf-icons/es/error";
import Account from "./account";
import css from "./navigation.module.css";

export default function Navigation() {
  const { unread } = Notification.useNotificationState();
  const [state, dispatch] = App.useGlobalState();
  const auth = Auth.useAuth();
  const navigate = Router.useNavigate();

  const toggleDrawer = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch({ type: "toggleDrawer" });
  };

  const overlayClasses = classNames(css.overlay, {
    [css.opened]: state.drawerOpened,
  });
  const drawerClasses = classNames(css.drawer, {
    [css.opened]: state.drawerOpened,
  });
  const notificationClasses = classNames(css.notifications, {
    [css.newNotifications]: unread.length > 0,
  });
  return (
    <Toast.Zone className={css.toastZone}>
      <nav className={css.toolbar}>
        <div className={css.menuIconOuter}>
          <MenuIcon onClick={toggleDrawer} className={css.menuIcon} />
        </div>
        {auth.signed && (
          <Router.Link to="/notifications" className={notificationClasses}>
            <NotificationsIcon className={css.notificationIcon} />
            {unread.length > 0 && <span className={css.pastille} />}
          </Router.Link>
        )}
      </nav>
      <div className={overlayClasses} onClick={toggleDrawer} />
      <nav className={drawerClasses}>
        <div className={css.drawerToolbar}>
          <CloseIcon onClick={toggleDrawer} className={css.closeIcon} />
        </div>
        <div className={css.navigation}>
          {auth.user && (
            <div className={css.account}>
              <Account onClick={toggleDrawer} />
            </div>
          )}
          {auth.user && auth.user.eligible === false && (
            <div className={css.eligible}>
              <Button
                iconLeft={<ErrorIcon />}
                variant="outlined"
                onClick={(e: React.MouseEvent) => {
                  navigate("/commande-carte-syndicale");
                  toggleDrawer(e);
                }}
                className={css.eligibleButton}
              >
                <div className={css.eligibleContent}>
                  Valider votre adhésion
                </div>
              </Button>
            </div>
          )}
          <React.Suspense fallback={<Loader />}>
            <NavigationMenu toggleDrawer={toggleDrawer} />
          </React.Suspense>
        </div>
      </nav>
    </Toast.Zone>
  );
}
