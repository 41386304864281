function isIPhone() {
    return window.navigator.userAgent.indexOf("iPhone") !== -1;
}
export function isGranted(permissions) {
    return permissions.status === "granted" && permissions.token !== undefined;
}
export function isUndetermined(permissions) {
    return isIPhone()
        ? permissions.status === "undetermined" && permissions.canAskAgain === true
        : permissions.status === "denied" && permissions.canAskAgain === true;
}
export function isDenied(permissions) {
    return permissions.status === "denied" && permissions.canAskAgain === false;
}
