import * as React from "react";
export function useIsMobile() {
    const mediaQuery = React.useMemo(() => window.matchMedia("(max-width: 768px)"), []);
    const [isMobile, setIsMobile] = React.useState(mediaQuery.matches);
    React.useEffect(() => {
        const listener = (e) => {
            setIsMobile(e.matches);
        };
        mediaQuery.addEventListener("change", listener);
        return () => mediaQuery.removeEventListener("change", listener);
    }, [mediaQuery]);
    return isMobile;
}
