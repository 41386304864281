import { jsx as _jsx } from "react/jsx-runtime";
export default function Close(props) {
    return (_jsx("svg", { viewBox: "0 -960 960 960", height: "24px", width: "24px", ...props, children: props.variant === "filled" ? (_jsx("use", { href: "#Close-filled" })) : (_jsx("use", { href: "#Close-outlined" })) }));
}
export function DefFilled() {
    return (_jsx("g", { id: "Close-filled", children: _jsx("path", { d: "M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z", fill: "currentColor" }) }));
}
export function DefOutlined() {
    return (_jsx("g", { id: "Close-outlined", children: _jsx("path", { d: "M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z", fill: "currentColor" }) }));
}
