import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import classNames from "classnames";
import * as Broker from "message-broker";
import * as App from "global-state";
import * as Auth from "oauth2";
import { usePrevious } from "hooks";
import * as Permission from "./notification-app-permission";
import Switch from "@valraiso-esf/esf-ui/es/switch";
import Dialog from "@valraiso-esf/esf-ui/es/dialog";
import Button from "@valraiso-esf/esf-ui/es/button";
import LoaderCircle from "@valraiso-esf/esf-ui/es/loader-circle";
import ErrorIcon from "@valraiso-esf/esf-icons/es/error";
import * as Query from "react-query";
import * as Api from "./services/api/notification";
import css from "./notification-app.module.css";
export default function NotificationApp() {
    const [waitingPermission, setWaitingPermission] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const { user } = Auth.useAuth();
    const [state] = App.useGlobalState();
    const { notification } = state.permissions;
    const previousPermission = usePrevious(notification);
    const { data: subscriptions, refetch } = Query.useQuery(["subscriptions", user?.login], () => Api.fetchSubscriptions());
    React.useEffect(() => {
        if (Broker.isNativeApp()) {
            Broker.sendToNative({
                type: "permission",
                payload: { type: "notification" },
            });
            setLoading(false);
        }
    }, []);
    const isSubscribed = React.useCallback(() => {
        return (notification.token !== undefined &&
            subscriptions?.expo.includes(notification.token));
    }, [notification.token, subscriptions?.expo]);
    const toggleExpoSubscription = React.useCallback(async () => {
        try {
            if (isSubscribed()) {
                await Api.fetchExpoUnsubscribe(notification.token);
            }
            else {
                await Api.fetchExpoSubscribe(notification.token);
            }
            await refetch();
        }
        catch (e) {
            setError(e);
            setOpen(true);
        }
        setLoading(false);
    }, [isSubscribed, notification.token, refetch]);
    React.useEffect(() => {
        if (waitingPermission && previousPermission !== notification) {
            if (Permission.isGranted(notification)) {
                toggleExpoSubscription();
                setWaitingPermission(false);
            }
            else if (Permission.isUndetermined(notification) ||
                Permission.isDenied(notification)) {
                setLoading(false);
                setWaitingPermission(false);
            }
        }
    }, [
        waitingPermission,
        toggleExpoSubscription,
        notification,
        previousPermission,
    ]);
    const notificationsChanged = async () => {
        if (Permission.isUndetermined(notification)) {
            setLoading(true);
            setWaitingPermission(true);
            await Broker.sendToNative({
                type: "askPermission",
                payload: { type: "notification" },
            });
        }
        else if (Permission.isGranted(notification)) {
            setLoading(true);
            await toggleExpoSubscription();
        }
    };
    const closeDialog = () => {
        setOpen(false);
        setError(null);
    };
    const switchClasses = classNames(css.switch, {
        [css.loading]: loading,
    });
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: switchClasses, children: [_jsx(Switch, { checked: Permission.isGranted(notification) && isSubscribed(), disabled: Permission.isDenied(notification), onChange: notificationsChanged, className: css.switchInput, children: "J'accepte de recevoir les notifications du Carnet Rouge sur cet appareil" }), loading && (_jsx("div", { className: css.loader, children: _jsx(LoaderCircle, {}) })), Permission.isDenied(notification) && (_jsxs("div", { className: css.help, children: [_jsx("b", { children: "Vous avez refus\u00E9 les notifications" }), _jsx("br", {}), "Pour les accepter \u00E0 nouveaux, vous devez modifier les permissions dans vos param\u00E8tres d'application"] }))] }), _jsx(Dialog, { open: open, title: "Une erreur est survenue", onClose: closeDialog, actions: [
                    _jsx(Button, { color: "primary", onClick: closeDialog, children: "Ok" }, "ok"),
                ], children: _jsxs("div", { className: css.error, children: [_jsx("div", { className: css.errorText, children: "Si le probl\u00E8me persiste, merci de contacter valraiso" }), error && (_jsxs("div", { className: css.errorObject, children: [_jsx(ErrorIcon, { className: css.errorIcon }), _jsxs("div", { children: [error.name, " : ", error.message] })] }))] }) })] }));
}
