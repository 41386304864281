import { jsx as _jsx } from "react/jsx-runtime";
export default function ChevronLeft(props) {
    return (_jsx("svg", { viewBox: "0 -960 960 960", height: "24px", width: "24px", ...props, children: props.variant === "filled" ? (_jsx("use", { href: "#ChevronLeft-filled" })) : (_jsx("use", { href: "#ChevronLeft-outlined" })) }));
}
export function DefFilled() {
    return (_jsx("g", { id: "ChevronLeft-filled", children: _jsx("path", { d: "m432-480 156 156q11 11 11 28t-11 28q-11 11-28 11t-28-11L348-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l184-184q11-11 28-11t28 11q11 11 11 28t-11 28L432-480Z", fill: "currentColor" }) }));
}
export function DefOutlined() {
    return (_jsx("g", { id: "ChevronLeft-outlined", children: _jsx("path", { d: "m432-480 156 156q11 11 11 28t-11 28q-11 11-28 11t-28-11L348-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l184-184q11-11 28-11t28 11q11 11 11 28t-11 28L432-480Z", fill: "currentColor" }) }));
}
