import devConfig from "./config/development.json";
import integrationConfig from "./config/integration.json";
import uatConfig from "./config/uat.json";
import prodConfig from "./config/production.json";
export function environment() {
    return import.meta.env.VITE_APP_ENV ?? "development";
}
export function current() {
    // REACT_APP_ENV is inlined on purpose
    // => it enables tree shaking of unused configurations
    // 🙏🏻 Do not change 😘
    if (import.meta.env.VITE_APP_ENV === "production") {
        return prodConfig;
    }
    else if (import.meta.env.VITE_APP_ENV === "uat") {
        return uatConfig;
    }
    else if (import.meta.env.VITE_APP_ENV === "integration") {
        return integrationConfig;
    }
    else {
        return devConfig;
    }
}
export function get(key) {
    return current()[key];
}
export function getOrigin() {
    const ORIGIN = import.meta.env.VITE_PUBLIC_URL;
    return Boolean(ORIGIN?.length) ? ORIGIN : "http://localhost:3000";
}
