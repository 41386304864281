import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import * as Hooks from "hooks";
export default function ResponsiveSwitch({ children }) {
    const isMobile = Hooks.useIsMobile();
    const childrenArray = checkChildren(children);
    const mobileChildren = childrenArray.filter((child) => {
        const element = child;
        return element.type === Mobile || element.type === Common;
    });
    const desktopChildren = childrenArray.filter((child) => {
        const element = child;
        return element.type === Desktop || element.type === Common;
    });
    const filteredChildren = React.useMemo(() => (isMobile ? mobileChildren : desktopChildren), [isMobile, desktopChildren, mobileChildren]);
    return _jsx(_Fragment, { children: filteredChildren });
}
function Mobile({ fallback, children }) {
    return fallback ? (_jsx(React.Suspense, { fallback: fallback, children: children })) : (_jsx(_Fragment, { children: children }));
}
function Desktop({ fallback, children }) {
    return fallback ? (_jsx(React.Suspense, { fallback: fallback, children: children })) : (_jsx(_Fragment, { children: children }));
}
function Common({ fallback, children }) {
    return fallback ? (_jsx(React.Suspense, { fallback: fallback, children: children })) : (_jsx(_Fragment, { children: children }));
}
ResponsiveSwitch.Mobile = Mobile;
ResponsiveSwitch.Desktop = Desktop;
ResponsiveSwitch.Common = Common;
function checkChildren(children) {
    const childs = React.Children.toArray(children);
    const invalidChildType = childs.some((child) => {
        const element = child;
        return (element.type !== Mobile &&
            element.type !== Desktop &&
            element.type !== Common);
    });
    if (invalidChildType) {
        throw new Error("Children must be of type ResponsiveSwitch.Mobile or ResponsiveSwitch.Desktop or ResponsiveSwitch.Common");
    }
    return childs;
}
