import css from "./ripple.module.css";
export function create(e, color, opacity) {
    let x, y;
    const container = e.currentTarget;
    const ripple = container.querySelector("." + css.ripple);
    if (ripple)
        ripple.remove();
    const bounds = container.getBoundingClientRect();
    const diameter = Math.max(bounds.width, bounds.height);
    const radius = diameter / 2;
    if ("clientX" in e) {
        x = e.clientX - (bounds.x + radius);
        y = e.clientY - (bounds.y + radius);
    }
    const rippleContainer = document.createElement("div");
    rippleContainer.classList.add(css.container);
    rippleContainer.style.borderRadius = getComputedStyle(container).borderRadius;
    const circle = document.createElement("span");
    if (color)
        circle.style.backgroundColor = color;
    if (opacity)
        circle.style.opacity = String(opacity);
    circle.style.width = `${diameter}px`;
    circle.style.height = `${diameter}px`;
    if ("clientX" in e) {
        circle.style.left = `${x}px`;
        circle.style.top = `${y}px`;
    }
    circle.classList.add(css.ripple);
    rippleContainer.append(circle);
    container.append(rippleContainer);
    setTimeout(() => rippleContainer.remove(), 600);
}
export const className = css.ripple;
