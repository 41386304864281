import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import IllustrationError from "@valraiso-esf/esf-icons/es/illustration-error";
import IllustrationEmptyCart from "@valraiso-esf/esf-icons/es/illustration-empty-cart";
import IllustrationFileSearch from "@valraiso-esf/esf-icons/es/illustration-file-search";
import IllustrationMountainPin from "@valraiso-esf/esf-icons/es/illustration-moutain-pin";
import css from "./empty.module.css";
const illustrations = {
    error: IllustrationError,
    "empty-cart": IllustrationEmptyCart,
    "file-search": IllustrationFileSearch,
    "mountain-pin": IllustrationMountainPin,
};
export default function Empty({ title, children, illustration = "error", className, }) {
    const classes = classNames(css.outer, className);
    const Illustration = illustrations[illustration];
    return (_jsx("div", { className: classes, children: _jsxs("div", { className: css.inner, children: [_jsx("div", { className: css.illustration, children: _jsx(Illustration, {}) }), title && _jsx("div", { className: css.title, children: title }), children && _jsx("div", { className: css.content, children: children })] }) }));
}
