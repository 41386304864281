import classNames from "classnames";
import * as Router from "react-router-dom";
import * as React from "react";
import * as Auth from "oauth2";
import * as App from "global-state";
import * as Format from "format";
import ChevronRightIcon from "@valraiso-esf/esf-icons/es/chevron-right";

import css from "./ecole-selector.module.css";

type Ecole = { code: number; name: string; isESF: boolean };
type Style = { width: number | undefined };
type Props = { ecoles: Ecole[] };

export default function EcoleSelector({ ecoles }: Props) {
  const { user } = Auth.useAuth();
  const ref = React.useRef<HTMLSelectElement>(null);

  const navigate = Router.useNavigate();
  const location = Router.useLocation();
  const [state, dispatch] = App.useGlobalState();
  const [style, setStyle] = React.useState<Style>({ width: undefined });

  React.useEffect(() => {
    const currentEcole = localStorage.getItem("ecole");
    let ecole =
      currentEcole !== null
        ? JSON.parse(currentEcole)
        : ecoleByCode(ecoles, user?.noEcole);

    if (user?.noEcole && !ecole && ecoles.length > 0) {
      // Cas des moniteurs qui sont rattachés à une école qui n'est pas une ESF (ex: E.N.S.A.)
      ecole = ecoleByCode(ecoles, ecoles[0].code);
    }

    dispatch({ type: "ecole", payload: ecole });
    dispatch({ type: "ecoles", payload: ecoles });
  }, [user?.noEcole, ecoles, dispatch]);

  React.useEffect(() => {
    setStyle({ width: computeWidth(ref.current, ecoles) });
  }, [ecoles, state.ecole?.code]);

  const changed = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.stopPropagation();
    // reset history state
    navigate(location.pathname, { replace: true, state: undefined });

    const code = Number(e.target.value);
    const ecole = ecoleByCode(ecoles, code);
    setStyle({ width: computeWidth(ref.current, ecoles) });
    dispatch({ type: "ecole", payload: ecole });
    if (ecole) {
      localStorage.setItem("ecole", JSON.stringify(ecole));
    } else {
      localStorage.removeItem("ecole");
    }
  };

  const computeWidth = (select: HTMLSelectElement | null, ecoles: Ecole[]) => {
    if (!select) return undefined;
    const s = document.createElement("select");
    const o = document.createElement("option");
    s.className = css.ecoles;
    o.textContent = select.selectedOptions[0].textContent as string;
    s.append(o);
    document.body.append(s);
    const width = s.offsetWidth;
    s.remove();
    const expanderWidth = ecoles.length === 1 ? 0 : 18;
    return width + expanderWidth;
  };

  const classes = classNames(css.selector, {
    [css.onlyOne]: ecoles.length === 1,
  });

  if (!ecoles) return null;

  return (
    <div className={classes}>
      <select
        ref={ref}
        className={css.ecoles}
        style={style}
        value={state.ecole?.code}
        onChange={changed}
      >
        {!user?.noEcole && <option>Choisir école...</option>}
        {ecoles.map((e) => (
          <option key={e.code} value={e.code}>
            {Format.capitalize(e.name)}
          </option>
        ))}
      </select>
      {ecoles.length > 1 && <ChevronRightIcon className={css.expand} />}
    </div>
  );
}

function ecoleByCode(ecoles: Ecole[], ecole: number | undefined) {
  return ecoles.find((e) => e.code === ecole);
}
