import * as React from "react";
import * as Auth from "oauth2";
import * as Config from "configuration";
const IPSOS_KEY = "enquete-PRO-QUALIF";
export function useEnqueteStatus() {
    const { user } = Auth.useAuth();
    const [status, setStatus] = React.useState(answerStatus(user?.login));
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
        const runEffect = async () => {
            if (!user) {
                return;
            }
            const answered = await fetchAnswerStatus(user.login);
            if (answered) {
                setStatus("answered");
            }
            setLoading(false);
        };
        if (status !== "answered") {
            runEffect();
        }
        else {
            setLoading(false);
        }
    }, [status, user]);
    return { status, setStatus, loading, IPSOS_KEY };
}
const ORIGIN = Config.get("apiValidationAdhesion");
function answerStatus(login = "") {
    const stored = localStorage.getItem(IPSOS_KEY + "-" + login);
    if (stored) {
        const parsed = JSON.parse(stored);
        if (parsed.enquete_sent) {
            return "answered";
        }
        else {
            return "answering";
        }
    }
    return "init";
}
async function fetchAnswerStatus(login) {
    const response = await Auth.fetch(`${ORIGIN}/api/validation-adhesion/enquete?login=${login}&enquete=${IPSOS_KEY}`);
    return response;
}
