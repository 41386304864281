import { jsx as _jsx } from "react/jsx-runtime";
export default function Star(props) {
    return (_jsx("svg", { viewBox: "0 0 24 24", height: "24px", width: "24px", ...props, children: props.variant === "filled" ? (_jsx("use", { href: "#Star-filled" })) : (_jsx("use", { href: "#Star-outlined" })) }));
}
export function DefFilled() {
    return (_jsx("g", { id: "Star-filled", children: _jsx("path", { d: "M12.0015 17.525L7.85148 20.025C7.66815 20.1416 7.47648 20.1916 7.27648 20.1749C7.07648 20.1583 6.90148 20.0916 6.75148 19.975C6.60148 19.8583 6.48481 19.7125 6.40148 19.5374C6.31815 19.3625 6.30148 19.1666 6.35148 18.95L7.45148 14.225L3.77648 11.05C3.60981 10.9 3.50565 10.7291 3.46398 10.5375C3.42231 10.3458 3.43481 10.1583 3.50148 9.97495C3.56815 9.79162 3.66815 9.64162 3.80148 9.52495C3.93481 9.40828 4.11815 9.33328 4.35148 9.29995L9.20148 8.87495L11.0765 4.42495C11.1598 4.22495 11.289 4.07495 11.464 3.97495C11.639 3.87495 11.8181 3.82495 12.0015 3.82495C12.1848 3.82495 12.364 3.87495 12.539 3.97495C12.714 4.07495 12.8431 4.22495 12.9265 4.42495L14.8015 8.87495L19.6515 9.29995C19.8848 9.33328 20.0681 9.40828 20.2015 9.52495C20.3348 9.64162 20.4348 9.79162 20.5015 9.97495C20.5681 10.1583 20.5806 10.3458 20.539 10.5375C20.4973 10.7291 20.3931 10.9 20.2265 11.05L16.5515 14.225L17.6515 18.95C17.7015 19.1666 17.6848 19.3625 17.6015 19.5374C17.5181 19.7125 17.4015 19.8583 17.2515 19.975C17.1015 20.0916 16.9265 20.1583 16.7265 20.1749C16.5265 20.1916 16.3348 20.1416 16.1515 20.025L12.0015 17.525Z", fill: "currentColor" }) }));
}
export function DefOutlined() {
    return (_jsx("g", { id: "Star-outlined", children: _jsx("path", { d: "M8.85197 17.0701L12.002 15.1701L15.152 17.0951L14.327 13.4951L17.102 11.0951L13.452 10.7701L12.002 7.37007L10.552 10.7451L6.90197 11.0701L9.67697 13.4951L8.85197 17.0701ZM12.002 17.5201L7.85197 20.0201C7.66863 20.1367 7.47697 20.1867 7.27697 20.1701C7.07697 20.1534 6.90197 20.0867 6.75197 19.9701C6.60197 19.8534 6.4853 19.7076 6.40197 19.5326C6.31863 19.3576 6.30197 19.1617 6.35197 18.9451L7.45197 14.2201L3.77697 11.0451C3.6103 10.8951 3.50613 10.7242 3.46447 10.5326C3.4228 10.3409 3.4353 10.1534 3.50197 9.97007C3.56863 9.78673 3.66863 9.63673 3.80197 9.52007C3.9353 9.4034 4.11863 9.3284 4.35197 9.29507L9.20197 8.87007L11.077 4.42007C11.1603 4.22007 11.2895 4.07007 11.4645 3.97007C11.6395 3.87007 11.8186 3.82007 12.002 3.82007C12.1853 3.82007 12.3645 3.87007 12.5395 3.97007C12.7145 4.07007 12.8436 4.22007 12.927 4.42007L14.802 8.87007L19.652 9.29507C19.8853 9.3284 20.0686 9.4034 20.202 9.52007C20.3353 9.63673 20.4353 9.78673 20.502 9.97007C20.5686 10.1534 20.5811 10.3409 20.5395 10.5326C20.4978 10.7242 20.3936 10.8951 20.227 11.0451L16.552 14.2201L17.652 18.9451C17.702 19.1617 17.6853 19.3576 17.602 19.5326C17.5186 19.7076 17.402 19.8534 17.252 19.9701C17.102 20.0867 16.927 20.1534 16.727 20.1701C16.527 20.1867 16.3353 20.1367 16.152 20.0201L12.002 17.5201Z", fill: "currentColor" }) }));
}
