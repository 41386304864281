import { jsx as _jsx } from "react/jsx-runtime";
export default function ChevronRight(props) {
    return (_jsx("svg", { viewBox: "0 -960 960 960", height: "24px", width: "24px", ...props, children: props.variant === "filled" ? (_jsx("use", { href: "#ChevronRight-filled" })) : (_jsx("use", { href: "#ChevronRight-outlined" })) }));
}
export function DefFilled() {
    return (_jsx("g", { id: "ChevronRight-filled", children: _jsx("path", { d: "M504-480 348-636q-11-11-11-28t11-28q11-11 28-11t28 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L404-268q-11 11-28 11t-28-11q-11-11-11-28t11-28l156-156Z", fill: "currentColor" }) }));
}
export function DefOutlined() {
    return (_jsx("g", { id: "ChevronRight-outlined", children: _jsx("path", { d: "M504-480 348-636q-11-11-11-28t11-28q11-11 28-11t28 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L404-268q-11 11-28 11t-28-11q-11-11-11-28t11-28l156-156Z", fill: "currentColor" }) }));
}
