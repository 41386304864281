import { jsx as _jsx } from "react/jsx-runtime";
export default function NestEcoLeaf(props) {
    return (_jsx("svg", { viewBox: "0 -960 960 960", height: "24px", width: "24px", ...props, children: props.variant === "filled" ? (_jsx("use", { href: "#NestEcoLeaf-filled" })) : (_jsx("use", { href: "#NestEcoLeaf-outlined" })) }));
}
export function DefFilled() {
    return (_jsx("g", { id: "NestEcoLeaf-filled", children: _jsx("path", { d: "M172-172q-11-11-11-28t11-28l55-55q-32-41-49.5-91T160-480q0-134 93-227t227-93h320v320q0 134-93 227t-227 93q-56 0-105.5-17.5T284-227l-56 55q-11 11-28 11t-28-11Zm162-162q11 13 28 12.5t29-12.5l157-157q12-12 12-28.5T548-548q-11-11-28-11t-28 11L334-390q-11 11-11 27.5t11 28.5Z", fill: "currentColor" }) }));
}
export function DefOutlined() {
    return (_jsx("g", { id: "NestEcoLeaf-outlined", children: _jsx("path", { d: "M480-160q-56 0-105.5-17.5T284-227l-56 55q-11 11-28 11t-28-11q-11-11-11-28t11-28l55-55q-32-41-49.5-91T160-480q0-134 93-227t227-93h320v320q0 134-93 227t-227 93Zm0-80q100 0 170-70t70-170v-240H480q-100 0-170 70t-70 170q0 39 12 74.5t33 64.5l207-207q11-11 28-11t28 11q12 12 12 28.5T548-491L341-284q29 21 64.5 32.5T480-240Zm0-240Z", fill: "currentColor" }) }));
}
