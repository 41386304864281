import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import * as OAuth from "./oauth";
import * as Config from "configuration";
import Loader from "@valraiso-esf/esf-ui/es/loader-circle";
import css from "./authorization.module.css";
export class AccessDeniedError extends Error {
    constructor(message) {
        super(message);
        this.name = "ACCESS_DENIED";
    }
}
export function tokenFromStorage() {
    const data = localStorage.getItem("authorization_token");
    if (!data) {
        return undefined;
    }
    return JSON.parse(data);
}
function userFromStorage() {
    const data = localStorage.getItem("authorization_user");
    if (!data) {
        return undefined;
    }
    return JSON.parse(data);
}
function accessTokenFromStorage() {
    const token = tokenFromStorage();
    return token !== undefined ? token.access_token : undefined;
}
const skipParams = ["code", "state", "scope", "session_state"];
function redirectToCleanURL(user) {
    const { origin, pathname, search } = window.location;
    const searchParams = new URLSearchParams(search);
    for (const p of skipParams) {
        searchParams.delete(p);
    }
    const params = searchParams.toString();
    const root = origin +
        (user && user.eligible === false ? "/commande-carte-syndicale" : pathname);
    const cleanedURL = params.length > 0 ? root + "?" + params : root;
    document.location.href = cleanedURL;
}
async function handleReturnFromCodeExchange(code, state) {
    const pkceState = sessionStorage.getItem("pkce_state");
    const pkceVerifier = sessionStorage.getItem("pkce_code_verifier");
    if (pkceState !== state) {
        throw new Error("Authorization : Invalid state");
    }
    if (pkceVerifier === null) {
        throw new Error("Authorization : Invalid code_verifier");
    }
    const data = await OAuth.exchangeCodeForAccessToken(code, pkceVerifier);
    sessionStorage.removeItem("pkce_code_verifier");
    sessionStorage.removeItem("pkce_state");
    if (!data) {
        return redirectToCleanURL();
    }
    localStorage.setItem("authorization_token", JSON.stringify(data));
    const user = await getAuthorization(false);
    return redirectToCleanURL(user);
}
const AuthContext = React.createContext({
    token: undefined,
});
export default function AuthProvider({ children }) {
    const token = accessTokenFromStorage();
    const user = userFromStorage();
    const [checking, setChecking] = React.useState(token !== undefined);
    if (token) {
        if (!user) {
            throw new AccessDeniedError("L'accès au Carnet Rouge ne vous a pas encore été délivré");
        }
        if (!hasAccessRole(user)) {
            throw new AccessDeniedError("L'accès au Carnet Rouge ne vous a pas encore été délivré (pas de rôle d'accès)");
        }
        if (!user.noEcole && hasEcoleAccessRole(user)) {
            throw new AccessDeniedError("Vous n'êtes pas encore associé à une école");
        }
    }
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const state = params.get("state");
    React.useEffect(() => {
        const reconnect = async () => {
            try {
                await getAuthorization(true);
            }
            catch (e) {
                console.error(e);
            }
            setChecking(false);
        };
        if (token) {
            reconnect();
        }
    }, [token]);
    React.useEffect(() => {
        const run = async (code, state) => {
            await handleReturnFromCodeExchange(code, state);
        };
        if (code !== null && state !== null) {
            run(code, state);
        }
    }, [code, state]);
    const context = React.useMemo(() => ({ token, user }), [token, user]);
    if ((code !== null && state !== null) || checking) {
        return (_jsxs("div", { className: css.outer, children: [_jsx("span", { children: "Connexion en cours..." }), _jsx(Loader, {})] }));
    }
    return (_jsx(AuthContext.Provider, { value: context, children: children }));
}
export function useAuth() {
    const auth = React.useContext(AuthContext);
    const token = tokenFromStorage();
    return {
        user: auth.user,
        token: `${token?.token_type || ""} ${auth.token}`.trim(),
        signed: auth.token !== undefined,
        signin: () => OAuth.signIn(),
        signout: () => OAuth.signOut(token),
    };
}
export function clear() {
    localStorage.removeItem("authorization_token");
    localStorage.removeItem("authorization_user");
    localStorage.removeItem("notification_date");
    localStorage.removeItem("notification_read");
    localStorage.removeItem("notification_unread");
    localStorage.removeItem("ecole");
}
export function deconnect() {
    const token = tokenFromStorage();
    OAuth.signOut(token);
}
async function getAuthorization(reconnect) {
    const token = tokenFromStorage();
    if (token) {
        const response = await fetchAuthorization(token);
        if (response.ok) {
            const user = (await response.json());
            localStorage.setItem("authorization_user", JSON.stringify(user));
            return user;
        }
        else {
            if (reconnect) {
                if (response.status === 401) {
                    const refreshed = await OAuth.exchangeRefreshForAccessToken(token);
                    if (refreshed) {
                        const refreshResponse = await fetchAuthorization(refreshed);
                        localStorage.setItem("authorization_token", JSON.stringify(refreshed));
                        if (refreshResponse.ok) {
                            const user = (await refreshResponse.json());
                            localStorage.setItem("authorization_user", JSON.stringify(user));
                            return user;
                        }
                    }
                }
            }
        }
    }
}
async function fetchAuthorization(token) {
    const api = Config.get("apiCarnetRouge");
    const url = `${api}/api/carnet-rouge/authorization?clearCache=true`;
    return fetch(url, {
        headers: {
            authorization: `${token.token_type} ${token.access_token}`.trim(),
        },
    });
}
const ECOLE_ACCESS_ROLES = new Set([
    // Rôles induits
    "MONITEUR",
    "DIR",
    "DT",
    // Rôles école
    "E_COLLAB_CR",
    "E_ASSDIR_CR",
    "E_COMPTA_CR",
    "E_EXTERNE_CR",
]);
const SNMSF_ACCESS_ROLES = new Set([
    // Rôles nationaux
    "S_ADMIN_CR",
    "S_COMITE_CR",
    "S_COLLAB_CR",
    "S_PRESTATAIRE_CR",
    "S_ACCIDENT_CR",
    "S_COMPTA_CR",
]);
const ACCESS_ROLES = new Set([...ECOLE_ACCESS_ROLES, ...SNMSF_ACCESS_ROLES]);
function hasAccessRole(user) {
    return intersection(user.roles, ACCESS_ROLES).length > 0;
}
function hasEcoleAccessRole(user) {
    return intersection(user.roles, ECOLE_ACCESS_ROLES).length > 0;
}
function intersection(a, b) {
    return a.filter((r) => b.has(r));
}
