import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import * as Broker from "message-broker";
import * as Router from "react-router-dom";
const initialState = {
    notification: undefined,
    permissions: { notification: {} },
    drawerOpened: false,
    ecole: undefined,
    ecoles: undefined,
    layoutElement: undefined,
};
const context = React.createContext([
    initialState,
    () => { },
]);
function reducer(state, action) {
    switch (action.type) {
        case "ecole":
            return { ...state, ecole: action.payload };
        case "ecoles":
            return { ...state, ecoles: action.payload };
        case "notification":
            return { ...state, notification: action.payload };
        case "permissions":
            const { type, ...rest } = action.payload || {};
            return type
                ? {
                    ...state,
                    permissions: {
                        ...state.permissions,
                        [type]: rest,
                    },
                }
                : state;
        case "notificationSubscription":
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    notification: {
                        ...state.permissions.notification,
                        ...action.payload,
                    },
                },
            };
        case "toggleDrawer":
            return { ...state, drawerOpened: !state.drawerOpened };
        case "closeDrawer":
            return { ...state, drawerOpened: false };
        case "layoutElement":
            return { ...state, layoutElement: action.payload };
    }
}
export function useGlobalState() {
    return React.useContext(context);
}
export default function GlobalStateProvider({ children }) {
    const value = React.useReducer(reducer, initialState);
    const [state, dispatch] = value;
    const navigate = Router.useNavigate();
    React.useEffect(() => {
        if (Broker.isNativeApp()) {
            const unsub = Broker.subscribeToNative(dispatch);
            Broker.sendToNative({ type: "loaded", payload: undefined });
            return () => unsub && Broker.unsubscribeToNative(unsub);
        }
        return undefined;
    }, [dispatch]);
    React.useEffect(() => {
        if (state.notification) {
            const { data } = state.notification;
            const path = data && data.path ? data.path : "/notifications";
            dispatch({ type: "notification", payload: undefined });
            navigate(path);
        }
    }, [dispatch, navigate, state.notification]);
    return _jsx(context.Provider, { value: value, children: children });
}
