import { jsx as _jsx } from "react/jsx-runtime";
export default function ExpandMore(props) {
    return (_jsx("svg", { viewBox: "0 -960 960 960", height: "24px", width: "24px", ...props, children: props.variant === "filled" ? (_jsx("use", { href: "#ExpandMore-filled" })) : (_jsx("use", { href: "#ExpandMore-outlined" })) }));
}
export function DefFilled() {
    return (_jsx("g", { id: "ExpandMore-filled", children: _jsx("path", { d: "M480-362q-8 0-15-2.5t-13-8.5L268-557q-11-11-11-28t11-28q11-11 28-11t28 11l156 156 156-156q11-11 28-11t28 11q11 11 11 28t-11 28L508-373q-6 6-13 8.5t-15 2.5Z", fill: "currentColor" }) }));
}
export function DefOutlined() {
    return (_jsx("g", { id: "ExpandMore-outlined", children: _jsx("path", { d: "M480-362q-8 0-15-2.5t-13-8.5L268-557q-11-11-11-28t11-28q11-11 28-11t28 11l156 156 156-156q11-11 28-11t28 11q11 11 11 28t-11 28L508-373q-6 6-13 8.5t-15 2.5Z", fill: "currentColor" }) }));
}
