import * as React from "react";
import * as Authorization from "oauth2/es/authorization";
import Screen from "common/es/screen";
import Button from "@valraiso-esf/esf-ui/es/button";
import Empty from "@valraiso-esf/esf-ui/es/empty";
import css from "./error-boundary.module.css";

type State = { error: Error | null; isAuthError: boolean };

type Props = { noHome?: boolean; children: React.ReactNode };

export default class ErrorBoundary extends React.Component<Props> {
  state: State = { error: null, isAuthError: false };

  static getDerivedStateFromError(error: Error) {
    return {
      error,
      isAuthError: error instanceof Authorization.AccessDeniedError,
    };
  }

  componentDidCatch() {
    // TODO : Report error server-side (post : error.message)
    // const { error } = this.state;
    // console.log(error?.name, error?.message, error?.stack);
  }

  tryAgain = () => {
    this.setState({ error: null });
  };

  goHome = () => {
    if (this.state.isAuthError) {
      Authorization.deconnect();
    } else {
      window.location.href = "/";
    }
  };

  getTitle = () => {
    if (this.state.isAuthError) {
      return "Accès interdit ✋🏻";
    } else {
      return "Erreur inattendue 😕";
    }
  };

  render() {
    const { error } = this.state;
    return (
      <>
        {error !== null && (
          <Screen>
            <Empty
              className={css.empty}
              title={this.getTitle()}
              illustration="error"
            >
              <div className={css.error}>{error.message}</div>
              <div className={css.actions}>
                {!this.state.isAuthError && (
                  <Button onClick={this.tryAgain} color="secondary">
                    Réessayer
                  </Button>
                )}
                {!this.props.noHome && (
                  <Button onClick={this.goHome}>Retour à l'accueil</Button>
                )}
              </div>
            </Empty>
          </Screen>
        )}
        {error === null && this.props.children}
      </>
    );
  }
}
