import * as Config from "configuration";
import * as Auth from "oauth2";
const origin = Config.get("apiNotification");
export async function fetchUnreadNotifications(date, path) {
    const url = `${origin}/api/notification/user-list/unread`;
    const qs = new URLSearchParams();
    qs.append("date", date.toISOString());
    if (path) {
        qs.append("path", path);
    }
    return Auth.fetch(url + "?" + qs.toString());
}
