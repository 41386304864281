import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import classNames from "classnames";
import CloseIcon from "@valraiso-esf/esf-icons/es/close";
import css from "./toast.module.css";
const ToastContext = React.createContext([[], [], noZone, () => { }]);
export default function Toast(props) {
    const [, closed, setOpened, setClosed] = React.useContext(ToastContext);
    const ref = React.useRef(null);
    const id = React.useId();
    React.useLayoutEffect(() => {
        setOpened((opened) => {
            const alreadyShown = Boolean(opened.find((t) => t.props.id === id) || closed.includes(id));
            if (alreadyShown) {
                return opened;
            }
            let timeout = null;
            if (!Boolean(props.permanent)) {
                timeout = closeTimer(id, ref, setOpened, setClosed, props.seconds);
            }
            const close = () => {
                if (timeout) {
                    clearTimeout(timeout);
                }
                closeToast(id, ref, setOpened, setClosed);
            };
            return [
                _jsx(ToastElement, { id: id, ref: ref, close: close, ...props }, id),
                ...opened,
            ];
        });
    }, [id, props, closed, setOpened, setClosed]);
    React.useLayoutEffect(() => {
        setTimeout(() => {
            if (ref.current)
                ref.current.style.opacity = "1";
        }, 10);
    });
    return null;
}
const ToastElement = React.forwardRef((props, ref) => {
    const role = props.variant === "error" ? "alert" : "status";
    const classes = classNames(css.toast, {
        [css.error]: props.variant === "error",
        [css.warning]: props.variant === "warning",
        [css.success]: props.variant === "success",
    });
    return (_jsxs("div", { id: props.id, ref: ref, className: classes, role: role, children: [_jsx("div", { className: css.left, children: props.left }), _jsx("div", { className: css.message, children: props.children }), _jsx(CloseIcon, { className: css.close, onClick: props.close, role: "button" })] }, props.id));
});
function ToastZone({ children, className }) {
    const [closed, setClosed] = React.useState([]);
    const [opened, setOpened] = React.useState([]);
    const classes = classNames(className, css.outer);
    const value = React.useMemo(() => {
        return [opened, closed, setOpened, setClosed];
    }, [opened, closed]);
    return (_jsxs(ToastContext.Provider, { value: value, children: [_jsx("div", { className: classes, children: opened }), children] }));
}
const eject = (setState) => (content) => {
    setState(content);
    requestAnimationFrame(() => setState(undefined));
};
Toast.Zone = ToastZone;
Toast.eject = eject;
function closeTimer(id, ref, setOpened, setClosed, seconds = 4) {
    return setTimeout(() => closeToast(id, ref, setOpened, setClosed), seconds * 1000);
}
function closeToast(id, ref, setOpened, setClosed) {
    if (ref.current)
        ref.current.style.opacity = "0";
    setTimeout(() => {
        setClosed((closed) => [...closed, id]);
        setOpened((opened) => opened.filter((t) => t.props.id !== id));
    }, 150);
}
function noZone() {
    throw new Error("To use a <Toast>, you must declare a <Toast.Zone> higher in the component tree");
}
