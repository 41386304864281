import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import * as Router from "react-router-dom";
import * as Ripple from "./ripple";
import classNames from "classnames";
import ExpandMoreIcon from "@valraiso-esf/esf-icons/es/expand-more";
import OpenInNewIcon from "@valraiso-esf/esf-icons/es/open-in-new";
import css from "./menu.module.css";
const MenuContext = React.createContext(null);
const defaultLink = (navigate, to, external = false) => {
    const url = to.toString();
    if (external) {
        return window.open(url, "_blank");
    }
    navigate(url);
};
export default function Menu({ variant, className, children, link, ...props }) {
    const parentContext = React.useContext(MenuContext);
    const root = parentContext === null;
    const context = React.useMemo(() => ({ variant, root, link }), [root, variant, link]);
    let icons = 0;
    for (const child of React.Children.toArray(children)) {
        const element = child;
        if (element.type !== Item) {
            throw new Error("Menu MUST only contain Menu.Item children");
        }
        if (element.props.icon) {
            icons++;
        }
    }
    const classes = classNames(className, css.menu, {
        [css.root]: root,
        [css.hasIcons]: icons > 0,
        [css.small]: variant === "small",
    });
    return root ? (_jsx(MenuContext.Provider, { value: context, children: _jsx("ul", { ...props, className: classes, role: "menu", children: children }) })) : (_jsx("ul", { ...props, className: classes, children: children }));
}
function Item({ icon: Icon, title, to, className, external = false, opened = false, active = false, onClick, disabled = false, onClickDisabled, children, ...props }) {
    const navigate = Router.useNavigate();
    const [isOpened, setOpened] = React.useState(opened);
    const [isClosing, setClosing] = React.useState(false);
    const context = React.useContext(MenuContext);
    for (const child of React.Children.toArray(children)) {
        const element = child;
        if (element.type !== Menu)
            throw new Error("Menu.Item MUST only contain Menu children");
    }
    const mousedown = (e) => {
        if (!disabled) {
            Ripple.create(e, "var(--color-grey-darker)", 0.2);
        }
    };
    const click = (e) => {
        if (disabled) {
            if (onClickDisabled)
                onClickDisabled(e);
            return;
        }
        setOpened((opened) => !opened);
        if (isOpened) {
            setClosing(true);
        }
        if (to) {
            if (onClick)
                onClick(e);
            const linkFn = context.link ? context.link : defaultLink;
            return linkFn(navigate, to, external);
        }
    };
    const transitionEnd = () => {
        if (isClosing) {
            setClosing(false);
        }
    };
    const classes = classNames(className, css.item, {
        [css.small]: context.variant === "small",
        [css.noIcon]: Icon === undefined,
        [css.active]: active,
        [css.disabled]: disabled,
    });
    const chevronClasses = classNames(css.chevron, {
        [css.opened]: isOpened,
    });
    const childrenClasses = classNames(css.children, {
        [css.closing]: isClosing,
    });
    return (_jsxs("li", { ...props, className: classes, role: "menuitem", children: [_jsxs("div", { className: css.title, onClick: click, "data-url": to, children: [context.root && Icon && (_jsx(Icon, { width: 32, height: 32, className: css.icon, variant: active ? "filled" : "outlined" })), typeof title === "string" ? (_jsxs("button", { className: css.name, onMouseDown: mousedown, tabIndex: 0, title: title, children: [_jsx("span", { className: css.nametext, children: title }), external && _jsx(OpenInNewIcon, { className: css.external })] })) : (_jsx("button", { className: css.name, onMouseDown: mousedown, tabIndex: 0, children: title })), children !== undefined && (_jsx("div", { className: css.chevronOuter, children: _jsx(ExpandMoreIcon, { className: chevronClasses }) }))] }), (isOpened || isClosing) && (_jsx("div", { className: childrenClasses, onTransitionEnd: transitionEnd, children: children }))] }));
}
Menu.Item = Item;
