import { jsx as _jsx } from "react/jsx-runtime";
import * as Broker from "message-broker";
import css from "./link.module.css";
import classNames from "classnames";
export default function Link({ navigate, to, external, children, className, }) {
    const classes = classNames(className, css.link);
    if (Broker.isWebApp() && external) {
        return (_jsx("a", { href: to, className: classes, target: "_blank", rel: "noreferrer", children: children }));
    }
    return (_jsx("div", { className: classes, onClick: () => link(navigate, to, external), children: children }));
}
export const link = (navigate, url, external = false) => {
    if (external) {
        sendOutboundLinkGoal(url);
        if (Broker.isWebApp()) {
            return window.open(url, "_blank");
        }
        if (Broker.isNativeApp()) {
            return Broker.sendToNative({ type: "openUrl", payload: url });
        }
    }
    else {
        navigate(url);
    }
};
const sendOutboundLinkGoal = (url) => {
    const urlObj = new URL(url);
    const path = urlObj.origin + urlObj.pathname;
    sendGoal("Outbound Link: Click", { url: path });
};
const sendGoal = (goal, props) => {
    if (window.plausible) {
        window.plausible(goal, { props });
    }
};
