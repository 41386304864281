import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as AcUnit from "./ac-unit";
import * as CloseIcon from "./close";
import * as MenuIcon from "./menu";
import * as EsfIcon from "./esf";
import * as SnmsfIcon from "./snmsf";
import * as BookIcon from "./book";
import * as LockIcon from "./lock";
import * as FlagIcon from "./flag";
import * as StarIcon from "./star";
import * as BookOnline from "./book-online";
import * as Landscape from "./landscape";
import * as Campaign from "./campaign";
import * as ExpandMoreIcon from "./expand-more";
import * as ChevronLeftIcon from "./chevron-left";
import * as ChevronRightIcon from "./chevron-right";
import * as NotificationsIcon from "./notifications";
import * as HomeIcon from "./home";
import * as BoltIcon from "./bolt";
import * as FolderIcon from "./folder";
import * as PermMedia from "./perm-media";
import * as NestEcoLeaf from "./nest-eco-leaf";
import * as CalendarMonth from "./calendar-month";
import css from "./global-icons-definition.module.css";
export default function GlobalIconsDefinition() {
    return (_jsx("svg", { className: css.def, children: _jsxs("defs", { children: [_jsx(CalendarMonth.DefFilled, {}), _jsx(CalendarMonth.DefOutlined, {}), _jsx(NestEcoLeaf.DefFilled, {}), _jsx(NestEcoLeaf.DefOutlined, {}), _jsx(PermMedia.DefFilled, {}), _jsx(PermMedia.DefOutlined, {}), _jsx(FolderIcon.DefFilled, {}), _jsx(FolderIcon.DefOutlined, {}), _jsx(BoltIcon.DefFilled, {}), _jsx(BoltIcon.DefOutlined, {}), _jsx(Landscape.DefFilled, {}), _jsx(Landscape.DefOutlined, {}), _jsx(BookOnline.DefFilled, {}), _jsx(BookOnline.DefOutlined, {}), _jsx(Campaign.DefFilled, {}), _jsx(Campaign.DefOutlined, {}), _jsx(AcUnit.DefFilled, {}), _jsx(AcUnit.DefOutlined, {}), _jsx(HomeIcon.DefFilled, {}), _jsx(HomeIcon.DefOutlined, {}), _jsx(EsfIcon.Def, {}), _jsx(MenuIcon.DefFilled, {}), _jsx(MenuIcon.DefOutlined, {}), _jsx(CloseIcon.DefFilled, {}), _jsx(CloseIcon.DefOutlined, {}), _jsx(FlagIcon.DefFilled, {}), _jsx(FlagIcon.DefOutlined, {}), _jsx(SnmsfIcon.Def, {}), _jsx(BookIcon.DefFilled, {}), _jsx(BookIcon.DefOutlined, {}), _jsx(LockIcon.DefFilled, {}), _jsx(LockIcon.DefOutlined, {}), _jsx(NotificationsIcon.DefFilled, {}), _jsx(NotificationsIcon.DefOutlined, {}), _jsx(ExpandMoreIcon.DefFilled, {}), _jsx(ExpandMoreIcon.DefOutlined, {}), _jsx(ChevronLeftIcon.DefFilled, {}), _jsx(ChevronLeftIcon.DefOutlined, {}), _jsx(ChevronRightIcon.DefFilled, {}), _jsx(ChevronRightIcon.DefOutlined, {}), _jsx(StarIcon.DefFilled, {}), _jsx(StarIcon.DefOutlined, {})] }) }));
}
