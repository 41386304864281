import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import classnames from "classnames";
import css from "./switch.module.css";
const Switch = React.forwardRef(({ className, checked, disabled = false, size = "M", onChange, onCheckedChange, onBlur, children, icon, error, ...props }, forwadedRef) => {
    const ref = React.useRef(null);
    const id = React.useId();
    const changed = (e) => {
        if (onChange) {
            onChange(e);
        }
        if (onCheckedChange) {
            onCheckedChange(e.target.checked);
        }
    };
    const blured = (e) => {
        if (onBlur) {
            onBlur(e);
        }
    };
    const classes = classnames(className, css.switch, css.variables, {
        [css.checked]: checked,
        [css.disabled]: disabled,
        [css.sizeM]: size === "M",
        [css.sizeL]: size === "L",
    });
    return (_jsxs("span", { className: classes, children: [_jsx("input", { type: "checkbox", role: "switch", className: css.input, onChange: changed, onBlur: blured, checked: checked, disabled: disabled, id: id, ref: (node) => {
                    ref.current = node;
                    if (typeof forwadedRef === "function") {
                        forwadedRef(node);
                    }
                    else if (forwadedRef) {
                        forwadedRef.current = node;
                    }
                }, ...props }), _jsxs("label", { htmlFor: id, className: css.label, children: [_jsx("div", { className: css.switchZone, children: _jsx("div", { className: css.switchRound, children: icon !== undefined && _jsx("div", { className: css.divIcon, children: icon }) }) }), children ? children : ""] }), error && _jsx("div", { className: css.error, children: error })] }));
});
export default Switch;
