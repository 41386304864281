import * as React from "react";
import Screen from "common/es/screen";
import Button from "@valraiso-esf/esf-ui/es/button";
import Empty from "@valraiso-esf/esf-ui/es/empty";
import css from "./404.module.css";

export default function Custom404() {
  const goHome = () => {
    window.location.href = "/";
  };
  return (
    <Screen title="404 Introuvable 😕">
      <Empty illustration="error">
        <div className={css.title}>
          Cet écran n'existe pas. Il va certainement bientôt arriver !
        </div>
        <div className={css.actions}>
          <Button onClick={goHome}>Retour à l'accueil</Button>
        </div>
      </Empty>
    </Screen>
  );
}
