import { jsx as _jsx } from "react/jsx-runtime";
export default function Bookmark(props) {
    return (_jsx("svg", { viewBox: "0 -960 960 960", height: "24px", width: "24px", ...props, children: props.variant === "filled" ? _jsx(DefFilled, {}) : _jsx(DefOutlined, {}) }));
}
function DefFilled() {
    return (_jsx("g", { id: "Bookmark-filled", children: _jsx("path", { d: "m480-240-168 72q-40 17-76-6.5T200-241v-519q0-33 23.5-56.5T280-840h400q33 0 56.5 23.5T760-760v519q0 43-36 66.5t-76 6.5l-168-72Z", fill: "currentColor" }) }));
}
function DefOutlined() {
    return (_jsx("g", { id: "Bookmark-outlined", children: _jsx("path", { d: "m480-240-168 72q-40 17-76-6.5T200-241v-519q0-33 23.5-56.5T280-840h400q33 0 56.5 23.5T760-760v519q0 43-36 66.5t-76 6.5l-168-72Zm0-88 200 86v-518H280v518l200-86Zm0-432H280h400-200Z", fill: "currentColor" }) }));
}
