import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import GlobalState from "global-state";
import Application from "./application";

// import { ReactQueryDevtools } from "react-query/devtools";

const container = document.getElementById("root");
container?.classList.remove("indexhtml");

const root = createRoot(container as HTMLElement);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: { suspense: true, staleTime: 1000 * 60 * 60 },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <GlobalState>
        <React.StrictMode>
          <Application />
        </React.StrictMode>
      </GlobalState>
    </BrowserRouter>
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  </QueryClientProvider>
);

// ServiceWorker
if (import.meta.env.PROD && "serviceWorker" in navigator) {
  navigator.serviceWorker.register("/sw.js");
}

// Plausible
if (import.meta.env.PROD) {
  const PLAUSIBLE_ID = "plausible-script";
  const plausible = document.getElementById(PLAUSIBLE_ID);
  if (!plausible) {
    const script = Object.assign(document.createElement("script"), {
      id: PLAUSIBLE_ID,
      src: "https://plausible.io/js/script.file-downloads.outbound-links.exclusions.js",
    });
    script.setAttribute("data-domain", "carnet-rouge-esf.app");
    script.setAttribute("data-exclude", "/ressource-web");
    document.head.appendChild(script);
  }
}

// function isIPhonePWA() {
//   return isIPhone() && window.matchMedia("(display-mode: fullscreen)").matches;
// }

// function isIPhone() {
//   return window.navigator.userAgent.indexOf("iPhone") !== -1;
// }
