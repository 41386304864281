const priceFormat = new Intl.NumberFormat("fr", {
    style: "currency",
    currency: "EUR",
});
const longDateFormat = new Intl.DateTimeFormat("fr", {
    year: "numeric",
    month: "long",
    day: "numeric",
});
const shortDateFormat = new Intl.DateTimeFormat("fr", {
    year: "numeric",
    month: "short",
    day: "numeric",
});
const shortNumDateFormat = new Intl.DateTimeFormat("fr", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
});
const hourFormat = new Intl.DateTimeFormat("fr", {
    hour: "numeric",
    minute: "numeric",
});
export function price(value) {
    return priceFormat.format(value);
}
export function dateLong(date) {
    return longDateFormat.format(date);
}
export function hour(date) {
    return hourFormat.format(date);
}
export function dateShort(date) {
    return shortDateFormat.format(date);
}
export function dateNumShort(date) {
    return shortNumDateFormat.format(date);
}
export function dateIso(date) {
    const yy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yy}-${mm}-${dd}`;
}
export function dateFr(date) {
    const yy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    return `${dd}/${mm}/${yy}`;
}
export function dateTimeIso(date) {
    return JSON.stringify(date).replace(/"/g, "");
}
export function dateIsoToFr(date) {
    const cs = date.split("-");
    return `${cs[2]}/${cs[1]}/${cs[0]}`;
}
export function timeShort(date) {
    const hh = String(date.getHours()).padStart(2, "0");
    const mm = String(date.getMinutes()).padStart(2, "0");
    return `${hh}:${mm}`;
}
export function fromToDates(from, to, verbose, gmt) {
    if (from === to)
        return dateLong(gmt ? createGMTDate(from) : new Date(from));
    return `${verbose ? "du " : ""}${dateShort(gmt ? createGMTDate(from) : new Date(from))}${verbose ? " au " : " - "} ${dateShort(gmt ? createGMTDate(to) : new Date(to))}`;
}
export function differenceMonthDates(date1, date2) {
    let months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();
    return months;
}
export function createGMTDate(fromDate) {
    const date = fromDate ? new Date(fromDate) : new Date();
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    date.setHours(0);
    const newDay = date.getDate();
    const newMonth = date.getMonth();
    const newYear = date.getFullYear();
    if (date.getTimezoneOffset() < 0 &&
        (newDay < day || newMonth < month || newYear < year)) {
        date.setDate(date.getDate() + 1);
    }
    return date;
}
export function capitalize(str) {
    const base = apostrophed(str.toLowerCase());
    const aposed = capitalizeMany(base, "'");
    const dashed = capitalizeMany(aposed, "-");
    const opened = capitalizeMany(dashed, "(");
    const closed = capitalizeMany(opened, ")");
    const spaced = capitalizeMany(closed, " ");
    return spaced;
}
export function normalize(str) {
    return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/(\u2018|\u2019)/g, "'")
        .toUpperCase();
}
const DAPOS_REGEX = / d'/gi;
function capitalizeMany(str, sep) {
    return apostrophed(str.split(sep).map(capitalizeOne).join(sep));
}
const NO_DAPOS_REGEX = / d /gi;
function apostrophed(str) {
    return str.replace(NO_DAPOS_REGEX, " d'").replace(DAPOS_REGEX, " d'");
}
const CAP_BLACKLIST = ["de", "des", "du", "sur", "en", "le", "les", "l"];
function capitalizeOne(str) {
    const low = str.toLowerCase();
    const stop = CAP_BLACKLIST.includes(low);
    if (str.length < 2 || stop) {
        return low;
    }
    if (str[0]) {
        return str[0].toUpperCase() + str.slice(1, str.length);
    }
    return str;
}
export function fileSize(bytes) {
    const thresh = 1024;
    const units = ["o", "ko", "Mo", "Go", "To", "Po", "Eo", "Zo", "Yo"];
    let u = 0;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes)) >= thresh && u < units.length - 1);
    return bytes.toFixed(0) + " " + units[u];
}
export function initials(fullName) {
    return fullName
        .toUpperCase()
        .split(" ")
        .filter((w) => w.length > 0)
        .map((w) => w.charAt(0))
        .join("");
}
