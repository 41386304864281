import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from "classnames";
import css from "./badge.module.css";
// TODO : surcharge couleur
export default function Badge({ color = "primary", variant = "filled", size = "M", iconLeft, iconRight, children, ...props }) {
    const mainClasses = classnames(props.className, css.badge);
    const badgeClasses = classnames(css.inner, {
        [css.primary]: color === "primary",
        [css.secondary]: color === "secondary",
        [css.tertiary]: color === "tertiary",
        [css.success]: color === "success",
        [css.warning]: color === "warning",
        [css.error]: color === "error",
        [css.black]: color === "black",
        [css.blue]: color === "blue",
        [css.filled]: variant === "filled",
        [css.outlined]: variant === "outlined",
        [css.sizeM]: size === "M",
        [css.sizeS]: size === "S",
        [css.sizeXS]: size === "XS",
    });
    return (_jsx("span", { ...props, role: "status", className: mainClasses, children: _jsxs("span", { className: badgeClasses, children: [iconLeft && _jsx("div", { className: css.iconLeft, children: iconLeft }), _jsx("div", { className: css.label, children: children }), iconRight && _jsx("div", { className: css.iconRight, children: iconRight })] }) }));
}
