import * as React from "react";
export function useLocalStorage(key, initialValue, parse, stringify) {
    const [storedValue, setStoredValue] = React.useState(() => {
        try {
            const item = localStorage.getItem(key);
            return item !== null
                ? parse
                    ? parse(item)
                    : JSON.parse(item)
                : initialValue;
        }
        catch (error) {
            console.log(error);
            return initialValue;
        }
    });
    React.useEffect(() => {
        if (storedValue !== undefined) {
            const _value = stringify
                ? stringify(storedValue)
                : JSON.stringify(storedValue);
            localStorage.setItem(key, _value);
        }
        else {
            localStorage.removeItem(key);
        }
    }, [key, storedValue, stringify]);
    return [storedValue, setStoredValue];
}
