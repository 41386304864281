import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import classnames from "classnames";
import CloseIcon from "@valraiso-esf/esf-icons/es/close";
import OGButton from "./button";
import css from "./snackbar.module.css";
const Context = React.createContext({});
const Snackbar = (props) => {
    const { classNames, closable } = props;
    const [open, setOpen] = React.useState(true);
    const onClose = React.useCallback(() => {
        if (closable) {
            if (typeof closable === "boolean") {
                if (closable) {
                    setOpen(false);
                }
            }
            else if (closable.onClose) {
                closable.onClose();
            }
        }
    }, [closable]);
    const context = React.useMemo(() => ({
        color: props.color,
    }), [props.color]);
    const role = props.color === "error" ? "alert" : "status";
    const classesMain = classnames(css.main, {
        [css.success]: props.color === "success",
        [css.warning]: props.color === "warning",
        [css.error]: props.color === "error",
    }, classNames?.main);
    const classesChildren = classnames(css.children, classNames?.children);
    const classesLeft = classnames(css.left, classNames?.left);
    const classesCross = classnames(css.cross, classNames?.cross);
    return open ? (_jsx(Context.Provider, { value: context, children: _jsxs("div", { className: classesMain, role: role, children: [_jsx("div", { className: classesLeft, children: props.left }), _jsx("div", { className: classesChildren, children: props.children }), closable && (_jsx("div", { className: classesCross, children: _jsx(CloseIcon, { onClick: onClose, className: css.cross }) }))] }) })) : null;
};
function Button({ className, ...props }) {
    const context = React.useContext(Context);
    const color = props.color ?? (context.color !== undefined ? context.color : "secondary");
    const size = props.size ?? "S";
    return _jsx(OGButton, { ...props, color: color, size: size });
}
Snackbar.Button = Button;
export default Snackbar;
