import * as Pkce from "./pkce";
import * as QueryString from "./querystring";
import * as Config from "configuration";
import * as Authorization from "./authorization";
import * as Broker from "message-broker";
const redirectUri = () => {
    if (import.meta.env.VITE_APP_ENV === "uat" && Broker.isNativeApp()) {
        return "https://services-uat.carnet-rouge-esf.app/api/carnet-rouge/go-to-app/";
    }
    else {
        return window.location.origin + "/";
    }
};
export const exchangeCodeForAccessToken = async (code, code_verifier) => {
    const config = Config.current();
    const url = `${config.oauth2Api}/connect/token`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json; charset=utf-8",
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: QueryString.stringify({
            grant_type: "authorization_code",
            client_id: config.oauth2ClientId,
            redirect_uri: redirectUri(),
            code,
            code_verifier,
        }),
    });
    if (response.status !== 200) {
        throw new Error(await response.text());
    }
    return response.json();
};
export const exchangeRefreshForAccessToken = async (token) => {
    const config = Config.current();
    const url = `${config.oauth2Api}/connect/token`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json; charset=utf-8",
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: QueryString.stringify({
            grant_type: "refresh_token",
            client_id: config.oauth2ClientId,
            refresh_token: token.refresh_token,
        }),
    });
    if (response.status !== 200) {
        return undefined;
    }
    return response.json();
};
export const signIn = () => {
    const config = Config.current();
    const state = Pkce.generateRandomString();
    const code_verifier = Pkce.generateRandomString();
    Pkce.challengeFromVerifier(code_verifier).then((code_challenge) => {
        const params = QueryString.stringify({
            grant_type: "authorization_code",
            response_type: "code",
            scope: config.oauth2Scope,
            client_id: config.oauth2ClientId,
            redirect_uri: redirectUri(),
            state,
            code_challenge,
            code_challenge_method: "S256",
        });
        sessionStorage.setItem("pkce_code_verifier", code_verifier);
        sessionStorage.setItem("pkce_state", state);
        const url = `${config.oauth2Api}/connect/authorize`;
        if (import.meta.env.VITE_APP_ENV === "uat") {
            if (Broker.isNativeApp()) {
                Broker.sendToNative({
                    type: "connexion",
                    payload: `${url}?${params}`,
                });
            }
            else {
                window.location.href = `${url}?${params}`;
            }
        }
        else {
            window.location.href = `${url}?${params}`;
        }
    });
};
export const signOut = (auth) => {
    const config = Config.current();
    const idToken = auth?.id_token;
    if (!idToken) {
        setTimeout(() => {
            window.location.href = "/";
        }, 100);
        return;
    }
    const params = QueryString.stringify({
        post_logout_redirect_uri: redirectUri(),
        id_token_hint: idToken,
    });
    Authorization.clear();
    const url = `${config.oauth2Api}/connect/endsession`;
    setTimeout(() => {
        if (import.meta.env.VITE_APP_ENV === "uat") {
            if (Broker.isNativeApp()) {
                Broker.sendToNative({
                    type: "connexion",
                    payload: `${url}?${params}`,
                });
            }
            else {
                window.location.href = `${url}?${params}`;
            }
        }
        else {
            window.location.href = `${url}?${params}`;
        }
    }, 100);
    return;
};
