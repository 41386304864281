import { jsx as _jsx } from "react/jsx-runtime";
export default function Landscape(props) {
    return (_jsx("svg", { viewBox: "0 -960 960 960", height: "24px", width: "24px", ...props, children: props.variant === "filled" ? (_jsx("use", { href: "#Landscape-filled" })) : (_jsx("use", { href: "#Landscape-outlined" })) }));
}
export function DefFilled() {
    return (_jsx("g", { id: "Landscape-filled", children: _jsx("path", { d: "m88-304 160-213q6-8 14.5-12t17.5-4q9 0 17.5 4t14.5 12l136 181q6 8 14 12t18 4q25 0 36-22.5t-4-42.5L410-520l118-157q6-8 14.5-12t17.5-4q9 0 17.5 4t14.5 12l280 373q15 20 4 42t-36 22H120q-25 0-36-22t4-42Z", fill: "currentColor" }) }));
}
export function DefOutlined() {
    return (_jsx("g", { id: "Landscape-outlined", children: _jsx("path", { d: "M120-240q-25 0-36-22t4-42l160-213q6-8 14.5-12t17.5-4q9 0 17.5 4t14.5 12l148 197h300L560-586 460-454l-50-66 118-157q6-8 14.5-12t17.5-4q9 0 17.5 4t14.5 12l280 373q15 20 4 42t-36 22H120Zm340-80h300-312 68.5H460Zm-260 0h160l-80-107-80 107Zm0 0h160-160Z", fill: "currentColor" }) }));
}
