import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import classnames from "classnames";
import * as Ripple from "./ripple";
import Badge from "./badge";
import css from "./button.module.css";
const Button = React.forwardRef(({ tag = "button", color = "primary", variant = "filled", size = "M", className, classNames, onMouseDown, onKeyDown, iconLeft, iconRight, badge, children, ...props }, ref) => {
    const down = React.useRef(false);
    const classes = classnames(css.button, {
        [css.sizeS]: size === "S",
        [css.filled]: variant === "filled",
        [css.outlined]: variant === "outlined",
        [css.text]: variant === "text",
        [css.icon]: variant === "icon",
        [css.primary]: color === "primary",
        [css.secondary]: color === "secondary",
        [css.tertiary]: color === "tertiary",
        [css.success]: color === "success",
        [css.warning]: color === "warning",
        [css.error]: color === "error",
    }, className, classNames?.main);
    const classesIconLeft = classnames(css.iconLeft, classNames?.iconLeft);
    const classesIconRight = classnames(css.iconRight, classNames?.iconRight);
    const classesUnderline = classnames(css.underline, classNames?.underline);
    const mousedowned = (e) => {
        e.stopPropagation();
        Ripple.create(e, "var(--color-grey-lightest)");
    };
    const keydowned = (e) => {
        e.stopPropagation();
        if (!down.current && (e.code === "Space" || e.code === "Enter")) {
            Ripple.create(e, "var(--color-grey-lightest)");
        }
        down.current = true;
    };
    const keyup = (e) => {
        e.stopPropagation();
        down.current = false;
    };
    const Badge_ = badge?.type === Badge ? (_jsx(Badge, { ...badge.props, variant: badge.props.variant ?? "outlined", color: badge.props.color ?? "black", size: badge.props.size ?? "XS", className: classnames(css.badge, {
            [css.badgeFilled]: (badge.props.variant ?? "outlined") === "filled",
            [css.badgeOutlined]: (badge.props.variant ?? "outlined") === "outlined",
        }, badge.props.className), children: badge.props.children })) : null;
    const Tag = tag;
    return (_jsxs(Tag, { ...props, tabIndex: tag === "a" ? 0 : undefined, className: classes, onMouseDown: mousedowned, onKeyDown: keydowned, onKeyUp: keyup, 
        // @ts-ignore
        ref: ref, children: [Badge_, iconLeft && _jsx("span", { className: classesIconLeft, children: iconLeft }), children, iconRight && _jsx("span", { className: classesIconRight, children: iconRight }), variant === "text" && _jsx("div", { className: classesUnderline })] }));
});
export default Button;
